<template>
	<div class="my-footer ">
		<div class="footer-first flex-center">
			<div class="footer-firstLeft">
				<img :src="proxy.$axios.imgUrl + bottomInfo.logo" class="footer-left-logo" />
				<div class="footer-left-title">{{bottomInfo.slogan}}</div>
				<div class="Site-navigation">Site navigation</div>
				<div class="footer-left-menus flex">
					<div :class="[footerTypes == index + 1 ? 'active' : '', 'footer-left-menus-item']"
						v-for="(item,index) in  menuList" :key="index" @click="jump(index)">
						{{item.title}}
					</div>
				</div>
			</div>
			<div class="footer-firstRight">
				<div class="footer-right-title">Contact us</div>
				<div class="footer-right-subhead">{{bottomInfo.web_site_title}}</div>
				<div class="footer-right-msg flex">
					<div class="msg-left ">
						<img src="../assets/image/ic_dizhi.png" />
						Address :
					</div>
					<div class="msg-right">{{bottomInfo.address}}</div>
				</div>
				<div class="footer-right-msg flex">
					<div class="msg-left alcenter">
						<img src="../assets/image/ic_wangz.png" />
						Website :
					</div>
					<div class="msg-right">{{bottomInfo.website}}</div>
				</div>
				<div class="footer-right-msg flex">
					<div class="msg-left alcenter">
						<img src="../assets/image/ic_phone.png" />
						Telephone :
					</div>
					<div class="msg-right ">{{bottomInfo.telephone}}</div>
				</div>
				<div class="footer-right-msg flex">
					<div class="msg-left alcenter">
						<img src="../assets/image/ic_mail.png" />
						E-mail :
					</div>
					<div class="msg-right">{{bottomInfo.e_mail}}</div>
				</div>
			</div>
		</div>
		<div class="footer-second flex-center">
			{{bottomInfo.copyright}}
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		defineProps,
		getCurrentInstance,
		onMounted
	} from 'vue';
	import {
		useRouter,
	} from 'vue-router'
	import EventBus from "../assets/common/event-bus.js"

	const {
		proxy
	} = getCurrentInstance()

	onMounted(() => {
		getInfo()
	})

	const menuList = ref([])

	const bottomInfo = ref({})

	async function getInfo() {
		let res = await proxy.$axios.get('api/index/getTopBottom')
		bottomInfo.value = res.data.bottom
		menuList.value = res.data.top.menu
	}


	const footerTypes = ref(1)

	EventBus.on('mgheadr', (val) => {
		footerTypes.value = val
	})

	const router = useRouter()

	function jump(type) {
		let arr = ['/', '/content', '/content', 'about']
		router.push({
			path: arr[type],
			query: {
				type: type + 1
			}
		})
	}
</script>

<style scoped>
	.my-footer {
		width: 100%;
		background-color: #122744;

	}

	.footer-first {
		background-color: #224269;
		padding: 0.16rem 0;
		box-sizing: border-box;

		color: #FFFFFF;
	}


	.footer-left-logo {
		width: 0.83rem;
		height: 0.09rem;
		display: block;
	}

	.footer-left-title {
		font-size: 0.09rem;
		line-height: 0.12rem;
		width: 1.63rem;
		margin-top: 0.09rem;
	}

	.Site-navigation {
		font-size: 0.06rem;
		margin-top: 0.14rem;
	}

	.footer-left-menus {
		margin-top: 0.08rem;

	}

	.footer-left-menus-item {
		font-size: 0.05rem;
		color: rgba(255, 255, 255, .5);
		line-height: 0.13rem;
		cursor: pointer;
	}

	.footer-left-menus-item.active {
		color: #008AD2;
	}

	.footer-left-menus-item:not(:first-child) {
		margin-left: 0.11rem;
	}

	.footer-left-menus-item.active {
		color: #008AD2;
		text-decoration-line: underline;
	}


	.footer-firstRight {
		margin-left: 1.3rem;
	}

	.footer-right-title {
		font-weight: 400;
		font-size: 0.06rem;
	}

	.footer-right-subhead {
		font-weight: 500;
		font-size: 0.12rem;
		margin-top: 0.04rem;
	}

	.footer-right-msg {
		margin-top: 0.06rem;
		font-weight: 400;
		font-size: 0.05rem;
		line-height: 0.09rem;
	}

	.footer-right-msg .msg-left>img {
		width: 0.06rem;
		height: 0.06rem;
		margin-right: 0.04rem;
	}

	.footer-right-msg .msg-right {
		margin-left: 0.04rem;
		width: 1.4rem;
	}

	.footer-second {
		font-size: 0.05rem;
		color: #AAAAAA;
		padding: 0.07rem 0;
	}
</style>