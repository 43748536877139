<template>
	<div>
		<div class="placeholder-box"></div>
		<div class="header-m flex-layout">
			<img :src="proxy.$axios.imgUrl + topInfo.logo" class="logo" />
			<div class="more">
				<img src="../assets/image/mobile/ic_sjdmenu.png" class="more-icon" @click="menuShow = true" />
				<div class="menu-popup flex" ref="menuPopup">
					<div class="menu-box" :style="{ width: menuShow ? '75%' : '0%' }">
						<div class="flex">
							<img src="../assets/image/mobile/ic_menuclose.png" class="close" @click="menuShow = false" />
						</div>
						<div class="menu-list">
							<div class="list-item" v-for="(item, index) in topInfo.menu" :key="index" @click="unfold(item)">
								<div class="itemTitle flex-layout">
									<div @click.stop="jump(index + 1, item)">{{ item.title }}</div>
									<img
										src="../assets/image/mobile/ic_menuerjdown.png"
										class="unfoldIcon"
										:style="{ transform: `rotate(${item.checked ? '180' : '0'}deg)` }"
										v-if="item.child.length"
									/>
								</div>
								<div v-if="item.checked">
									<div class="menu-childList" v-for="(items, indexs) in item.child" :key="indexs" @click.stop="jump(index + 1, item, items)">
										{{ items.title }}
									</div>
								</div>
							</div>
							<div class="list-item" @click="CN">
								<div class="itemTitle flex-layout">CN</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, defineProps, getCurrentInstance, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import EventBus from '../assets/common/event-bus.js';

const { proxy } = getCurrentInstance();

onMounted(() => {
	menuPopup.value.style.display = 'none';
	getInfo();
});

/**
 * 获取数据
 */
const topInfo = ref({});

async function getInfo() {
	let res = await proxy.$axios.get('api/index/getTopBottom', {});
	res.data.top.menu.map((v) => {
		v.checked = false;
	});
	topInfo.value = res.data.top;
}

/**
 * 当前所处的类别
 */
const headTypes = ref(1);

EventBus.on('mgheadr', (val) => {
	headTypes.value = val;
});

const router = useRouter();

function jump(type, item, items) {
	// if (Array.isArray(item.child) && item.child.length) return
	router.push({
		path:
			type == 1
				? '/index-m'
				: type == 4 && items?.id == 20
				? '/about-m'
				: type == 4 && items?.id == 23
				? '/contactUs-m'
				: type == 4 && items?.id == 22
				? '/newsList-m'
				: type == 3 || type == 2
				? '/content-m'
				: type == 4
				? '/about-m'
				: '',
		query: {
			type,
			id: items?.id
		}
	});
	menuShow.value = false;
}

const menuShow = ref(false);

const menuPopup = ref(null);

watch(
	() => menuShow.value,
	(newValue, oldValue) => {
		if (newValue) {
			menuPopup.value.style.display = '';
		} else {
			setTimeout(() => {
				menuPopup.value.style.display = 'none';
			}, 200);
		}
	}
);

function unfold(item) {
	item.checked = !item.checked;
}

function CN() {
	window.open('https://www.safeglp.com');
}
</script>

<style scoped>
.placeholder-box {
	height: 0.88rem;
	width: 100%;
}

.header-m {
	width: 100%;
	padding: 0.24rem 0.3rem;
	box-sizing: border-box;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
	background-color: #fff;
}

.logo {
	width: 2.64rem;
	height: 0.28rem;
}

.more {
}

.more-icon {
	width: 0.39rem;
	height: 0.39rem;
	display: block;
}

.menu-popup {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.64);
	justify-content: flex-end;
	z-index: 999;
}

.menu-box {
	width: 75%;
	background-color: #fff;
	transition: 0.3s;
}

.menu-box > .flex {
	display: flex;
	justify-content: flex-end;
	padding: 0.35rem;
}

.menu-box > .flex .close {
	width: 0.29rem;
	height: 0.29rem;
}

.menu-list {
	margin-top: 0.2rem;
}

.menu-list .list-item {
	/* display: block; */
	width: 100%;
	/* padding: 0 0.34rem 0 0.6rem; */
	box-sizing: border-box;
	font-weight: 500;
	font-size: 0.32rem;
	color: #000000;
}

.menu-list .list-item .itemTitle {
	padding: 0.3rem 0.34rem 0.3rem 0.6rem;
	box-sizing: border-box;
	border-bottom: 1px solid #eeeeee;
}

.menu-list .list-item .unfoldIcon {
	width: 0.21rem;
	height: 0.12rem;
	transform: 0.2s;
	display: block;
}

.menu-list .list-item .menu-childList {
	display: block;
	font-weight: 400;
	font-size: 0.26rem;
	color: #666666;
	line-height: 0.26rem;
	padding: 0.2rem 0.5rem 0.2rem 0.6rem;
	box-sizing: border-box;
}
</style>
