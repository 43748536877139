<template>
	<div class="my-header">
		<div class="content flex-center">
			<img :src="proxy.$axios.imgUrl + topInfo.logo" class="content-left" />
			<div class="content-right">
				<ul class="header-list alcenter">
					<li v-for="(item, index) in topInfo.menu" :key="index" :style="{ color: headTypes == index + 1 ? '#008AD2' : '' }" @click="jump(index + 1, item)">
						{{ item.title }}
						<div class="header-list-border" v-if="headTypes == index + 1"></div>
						<ul class="header-second" v-if="item.child.length">
							<li @click.stop="jump(index + 1, item, items)" v-for="(items, indexs) in item.child" :key="indexs">
								{{ items.title }}
							</li>
						</ul>
					</li>
					<li @click="CN">中</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, defineProps, getCurrentInstance, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import EventBus from '../assets/common/event-bus.js';

const { proxy } = getCurrentInstance();

onMounted(() => {
	getInfo();
});

/**
 * 获取数据
 */
const topInfo = ref({});

async function getInfo() {
	let res = await proxy.$axios.get('api/index/getTopBottom', {});
	topInfo.value = res.data.top;
}

/**
 * 当前所处的类别
 */
const headTypes = ref(1);

EventBus.on('mgheadr', (val) => {
	headTypes.value = val;
});

const router = useRouter();

function jump(type, item, items) {
	// if (Array.isArray(item.child) && item.child.length) return
	router.push({
		path:
			type == 1
				? '/'
				: type == 4 && items?.id == 20
				? '/about'
				: type == 4 && items?.id == 23
				? '/contactUs'
				: type == 4 && items?.id == 22
				? '/newsList'
				: type == 3 || type == 2
				? '/content'
				: type == 4
				? '/about'
				: '',
		query: {
			type,
			id: items?.id
		}
	});
}

// const prop = defineProps({
// 	types: {
// 		type: [String, Number],
// 		default: ''
// 	}
// })
// const headerIndex = ref(0)

// function headerSelect(index) {
// 	headerIndex.value = index
// }

function CN() {
	window.open('https://www.safeglp.com');
}
</script>

<style scoped>
.my-header {
	margin: 0 auto;
	z-index: 999;
	height: 0.3rem;
}

.content {
	width: 100%;
	height: 0.3rem;
	position: fixed;
	top: 0;
	z-index: 999;
	background-color: #fff;
}

.content-left {
	width: 1.13rem;
	height: 0.12rem;
}

.content-right {
	margin-left: 1.23rem;
	font-size: 0.08rem;
}

.el-menu-demo {
	width: 100%;
	height: 100%;
	font-size: 0.08rem;
}

.header-list {
	list-style-type: none;
	font-size: 0.06rem;
	color: #cccccc;
}

.header-list > li {
	margin: 0 0.17rem;
	font-size: 0.1rem;
	color: #000000;
	cursor: pointer;
	padding: 0.1rem 0;
	border-bottom: 0.01rem solid transparent;
	position: relative;
	box-sizing: border-box;
}

.header-list-border {
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
	width: 0.13rem;
	height: 1px;
	background: #008ad2;
}

.header-list > li:first-child {
	margin-left: 0;
}

.header-list > li:last-child {
	margin-right: 0;
}

.header-list > li:hover {
	color: #008ad2;
	border-color: #008ad2;
}

.header-list > li:hover .header-second,
.header-list .header-second:hover {
	display: block;
}

.header-list .header-second {
	position: absolute;
	top: 100%;
	left: 0;
	transform: translate(-40%, 0.01rem);
	color: #333333;
	padding: 0 0.1rem;
	background-color: #fff;
	width: 1.02rem;
	box-sizing: border-box;
	border: 0.01rem solid #eeeeee;
	display: none;
	z-index: 999;
}

.header-list .header-second > li {
	padding: 0.1rem 0;
	font-size: 0.08rem;
	border-top: 0.01rem solid #eeeeee;
}

.header-list .header-second > li:first-child {
	border: none;
}

.header-list .header-second > li:hover {
	color: #008ad2;
}
</style>
