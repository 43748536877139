import {
	createApp,
	reactive
} from 'vue'
import App from './App.vue'
import myheader from './components/my-header.vue'
import myfooter from './components/my-footer.vue'
import mobileheader from './components/mobile-header.vue'
import mobilefooter from './components/mobile-footer.vue'
import ElementPlus from 'element-plus'
import router from './router'
import 'element-plus/dist/index.css'
import axios from './utils/axios/request.js'




const app = createApp(App)

app.config.globalProperties.$axios = axios

app.component('myheader', myheader)
app.component('myfooter', myfooter)
app.component('mobileheader', mobileheader)
app.component('mobilefooter', mobilefooter)
app.use(ElementPlus)
app.use(router)
app.mount('#app')