import {
	createRouter,
	createWebHistory,
	createWebHashHistory
} from 'vue-router'

const routes = [{
		path: '/',
		name: 'home',
		component: () => import('../view/pc/home.vue')
	},
	{
		path: '/content',
		name: 'content',
		component: () => import('../view/pc/content.vue')
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('../view/pc/about.vue')
	},
	{
		path: '/newsList',
		name: 'newsList',
		component: () => import('../view/pc/newsList.vue')
	},
	{
		path: '/newsdetail',
		name: 'newsdetail',
		component: () => import('../view/pc/news-detail.vue')
	},
	{
		path: '/contactUs',
		name: 'contactUs',
		component: () => import('../view/pc/contactUs.vue')
	},
	//手机端首页
	{
		path: '/index-m',
		name: 'index-m',
		component: () => import('../view/mobile/index-m.vue'),
	},
	{
		path: '/content-m',
		name: 'content-m',
		component: () => import('../view/mobile/content-m.vue'),
	}, {
		path: '/about-m',
		name: 'about-m',
		component: () => import('../view/mobile/about-m.vue'),
	}, {
		path: '/contactUs-m',
		name: 'contactUs-m',
		component: () => import('../view/mobile/contactUs-m.vue'),
	}, {
		path: '/newsList-m',
		name: 'newsList-m',
		component: () => import('../view/mobile/newsList-m.vue'),
	}, {
		path: '/newsdetail-m',
		name: 'newsdetail-m',
		component: () => import('../view/mobile/news-detail-m.vue'),
	}


]


const router = createRouter({
	history: createWebHashHistory(),
	routes
})

router.afterEach((to, from, next) => {
	document.querySelector("body").setAttribute("style", "overflow: auto !important;")
	window.scrollTo(0, 0)
});

export default router