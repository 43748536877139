<template>
	<mobileheader v-if="isMobile()"></mobileheader>
	<myheader v-else></myheader>
	<router-view :key="routerKey"></router-view>
	<mobilefooter v-if="isMobile()"></mobilefooter>
	<myfooter v-else></myfooter>
</template>

<script setup>
	import {
		computed,
		onMounted
	} from 'vue';
	import {
		useRoute,
		useRouter
	} from 'vue-router'

	const route = useRoute()

	const router = useRouter()


	const isMobile = () => {
		let flag = navigator.userAgent.match(
			/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			)
		return flag;
	}

	onMounted(() => {
		if (route.path === '/' || route.path === '/index-m') {
			if (isMobile()) {
				router.replace('/index-m')
			} else {
				router.replace('/')
			}
		}
	})

	const routerKey = computed(() => {
		return route.path + Math.random()
	})
</script>

<style>
	* {
		padding: 0;
		margin: 0;
	}
	
	.main{
		padding: 0 0.3rem;
		box-sizing: border-box;
	}

	.flex {
		display: flex;
	}

	.flex-center {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.flex-layout {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.alcenter {
		display: flex;
		align-items: center;
	}

	.jucenter {
		display: flex;
		justify-content: center;
	}

	li {
		list-style-type: none;
	}
</style>