<template>
	<div>
		<div class="footer-first-m">
			<img :src="proxy.$axios.imgUrl + bottomInfo.logo" class="footer-logo-m" />
			<div class="footer-title">{{bottomInfo.slogan}}</div>
			<div class="footer-left-menus flex-center">
				<div :class="[footerTypes == index + 1 ? 'active' : '', 'footer-left-menus-item']"
					v-for="(item,index) in  menuList" :key="index" @click="jump(index)">
					{{item.title}}
				</div>
			</div>
			<div class="footer-subhead">{{bottomInfo.web_site_title}}</div>

			<div class="footer-msg jucenter">
				<div class="msg-left">
					Address :
				</div>
				<div class="msg-right">{{bottomInfo.address}}</div>
			</div>

			<div class="footer-msg flex-center">
				<div class="msg-left">
					Website :
				</div>
				<div class="msg-right">{{bottomInfo.website}}</div>
			</div>

			<div class="footer-msg flex-center">
				<div class="msg-left">
					Telephone :
				</div>
				<div class="msg-right">{{bottomInfo.telephone}}</div>
			</div>
			<div class="footer-msg flex-center">
				<div class="msg-left">
					E-mail :
				</div>
				<div class="msg-right">{{bottomInfo.e_mail}}</div>
			</div>
		</div>

		<div class="footer-second flex-center">
			{{bottomInfo.copyright}}
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		defineProps,
		getCurrentInstance,
		onMounted
	} from 'vue';
	import {
		useRouter,
	} from 'vue-router'
	import EventBus from "../assets/common/event-bus.js"

	const {
		proxy
	} = getCurrentInstance()

	onMounted(() => {
		getInfo()
	})

	const menuList = ref([])

	const bottomInfo = ref({})

	async function getInfo() {
		let res = await proxy.$axios.get('api/index/getTopBottom')
		bottomInfo.value = res.data.bottom
		menuList.value = res.data.top.menu
	}


	const footerTypes = ref(1)

	EventBus.on('mgheadr', (val) => {
		footerTypes.value = val
	})

	const router = useRouter()

	function jump(type) {
		let arr = ['/index-m', '/content-m', '/content-m', 'about-m']
		router.push({
			path: arr[type],
			query: {
				type: type + 1
			}
		})
	}
</script>

<style scoped>
	.footer-first-m {
		width: 100%;
		background: #224269;
		padding-top: 0.44rem;
		padding-bottom: 0.52rem;
		color: #fff;
		text-align: center;
	}

	.footer-logo-m {
		width: 2.7rem;
		height: 0.28rem;
		display: block;
		margin: 0 auto;
	}

	.footer-title {
		font-weight: 500;
		font-size: 0.24rem;
		line-height: 0.24rem;
		margin-top: 0.32rem;
	}

	.footer-left-menus {
		margin-top: 0.39rem;
		font-weight: 400;
		font-size: 0.2rem;
	}

	.footer-left-menus-item {
		color: rgba(255, 255, 255, .5);
	}

	.footer-left-menus-item.active {
		color: #008AD2;
		text-decoration-line: underline;
	}

	.footer-left-menus-item:not(:first-child) {
		margin-left: 0.39rem;
	}

	.footer-subhead {
		font-weight: 500;
		font-size: 0.26rem;
		line-height: 0.34rem;
		margin-top: 0.77rem;
	}

	.footer-msg {
		font-weight: 400;
		font-size: 0.2rem;
		line-height: 0.28rem;
		margin-top: 0.24rem;
	}

	.footer-msg .msg-right {
		width: 5.11rem;
		margin-left: 0.18rem;
		text-align: left;
	}

	.footer-second {
		background: #122744;
		padding: 0.26rem 0.84rem;
		color: #AAAAAA;
		text-align: center;
		font-weight: 400;
		font-size: 0.2rem;
		color: #AAAAAA;
		line-height: 0.28rem;

	}
</style>